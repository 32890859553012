<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmorganization.title')">
					<v-layout wrap v-if="isSuperAdmin">
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('pmorganization.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								v-model="model.acronym"
								:label="$t('pmorganization.acronym')"
								maxlength="5"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="pmsubscriptionid"
								:label="this.$t('pmorganization.subscription')"
								:placeholder="this.$t('pmorganization.phsubscription')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmsubscription"
								:modelFormMapping="{ pmsubscriptionid: 'pmsubscriptionid' }"
								:itemsToSelect="subscriptionItemsToSelect"
								itemValue="pmsubscriptionid"
								itemText="acronym"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="isUpdateEntry" :md6="!isUpdateEntry">
							<pui-select
								attach="pmdateformatid"
								:label="this.$t('pmorganization.dateformat')"
								:placeholder="this.$t('pmorganization.phdateformat')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmdateformat"
								:modelFormMapping="{ pmdateformatid: 'pmdateformatid' }"
								:itemsToSelect="dateFormatItemsToSelect"
								itemValue="pmdateformatid"
								itemText="acronym"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="isUpdateEntry" :md6="!isUpdateEntry">
							<pui-select
								attach="pmtimezoneid"
								:label="this.$t('pmorganization.timezone')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmtimezone"
								:modelFormMapping="{ pmtimezoneid: 'pmtimezoneid' }"
								:itemsToSelect="timezoneItemsToSelect"
								itemValue="pmtimezoneid"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="isUpdateEntry" :md6="!isUpdateEntry">
							<pui-select
								attach="organizationlanguage"
								:label="this.$t('pmorganization.organizationlanguage')"
								:placeholder="this.$t('pmorganization.phorganizationlanguage')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="puilanguage"
								:modelFormMapping="{ isocode: 'organizationlanguage' }"
								:itemsToSelect="languageItemsToSelect"
								itemValue="isocode"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex xs12 md6>
							<div class="label_container">
								<label class="fixed_label">
									{{ this.$t('pmorganization.icon') }}
									<span style="color: var(--R-200)">*</span>
								</label>
							</div>
							<v-select
								v-model="iconSelected"
								:items="items"
								:placeholder="this.$t('pmorganization.phicon')"
								clearable
								:disabled="formDisabled"
								rounded
								:rules="[rules.required]"
								class="pt-5 px-1 mt-auto"
							>
								<template v-slot:selection="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
							</v-select>
						</v-flex>
						<v-flex v-if="isUpdateEntry" xs12 md6>
							<pui-text-field :label="$t('pmorganization.token')" toplabel disabled v-model="model.token"></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap v-else>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('pmorganization.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<!--<pui-text-field :label="$t('pmorganization.dateformat')" toplabel required v-model="model.dateformat"></pui-text-field>-->
							<pui-select
								attach="pmdateformatid"
								:label="this.$t('pmorganization.dateformat')"
								:placeholder="this.$t('pmorganization.dateformat')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmdateformat"
								:modelFormMapping="{ pmdateformatid: 'pmdateformatid' }"
								:itemsToSelect="dateFormatItemsToSelect"
								itemValue="pmdateformatid"
								itemText="acronym"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="isUpdateEntry" :md6="!isUpdateEntry">
							<pui-select
								attach="pmtimezoneid"
								:label="this.$t('pmorganization.timezone')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmtimezone"
								:modelFormMapping="{ pmtimezoneid: 'pmtimezoneid' }"
								:itemsToSelect="timezoneItemsToSelect"
								itemValue="pmtimezoneid"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="isUpdateEntry" :md6="!isUpdateEntry">
							<pui-select
								attach="organizationlanguage"
								:label="this.$t('pmorganization.organizationlanguage')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="puilanguage"
								:modelFormMapping="{ isocode: 'organizationlanguage' }"
								:itemsToSelect="languageItemsToSelect"
								itemValue="isocode"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex v-if="isUpdateEntry" xs12 md4>
							<pui-text-field :label="$t('pmorganization.token')" toplabel disabled v-model="model.token"></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmorganization.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap v-if="isSuperAdmin">
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								:label="$t('pmorganization.usesatelliteimages')"
								v-model="model.usesatelliteimages"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								v-if="isSuperAdmin && model.acronym == 'PRO'"
								:label="$t('pmorganization.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
								:tooltipDescription="$t('pmorganization.easter')"
							></pui-checkbox>
							<pui-checkbox
								v-else
								:label="$t('pmorganization.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								:label="$t('pmorganization.shownotifications')"
								v-model="model.shownotifications"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								:label="$t('pmorganization.showaqi')"
								v-model="model.showaqi"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								:label="$t('pmorganization.showexternallayers')"
								v-model="model.showexternallayers"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 md3 lg2 class="my-2">
							<pui-checkbox
								:label="$t('pmorganization.showgeojsonlayers')"
								v-model="model.showgeojsonlayers"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmorganization.contact')">
						<v-layout wrap>
							<v-flex xs12 md3>
								<pui-text-field
									:label="$t('pmorganization.country')"
									toplabel
									v-model="model.country"
									maxlength="50"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field :label="$t('pmorganization.city')" toplabel v-model="model.city" maxlength="10"></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field
									:label="$t('pmorganization.postalcode')"
									toplabel
									v-model="model.postalcode"
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-text-field
									:label="$t('pmorganization.address')"
									toplabel
									v-model="model.address"
									maxlength="100"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4>
								<pui-text-field :label="$t('pmorganization.url')" toplabel v-model="model.url" maxlength="100"></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field :label="$t('pmorganization.phone')" toplabel v-model="model.phone" maxlength="10"></pui-text-field>
							</v-flex>
							<v-flex xs12 md4>
								<pui-text-field :label="$t('pmorganization.email')" toplabel v-model="model.email" maxlength="100"></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="$t('pmorganization.location')">
						<v-layout wrap>
							<v-flex xs12 md3>
								<pui-number-field v-model="model.swlat" :label="$t('pmorganization.swlat')" toplabel></pui-number-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-number-field v-model="model.swlon" :label="$t('pmorganization.swlon')" toplabel></pui-number-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-number-field v-model="model.nelat" :label="$t('pmorganization.nelat')" toplabel></pui-number-field>
							</v-flex>
							<v-flex xs12 md3>
								<pui-number-field v-model="model.nelon" :label="$t('pmorganization.nelon')" toplabel></pui-number-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="$t('pmorganization.providers')">
						<v-layout wrap>
							<pui-multi-select
								id="multiSelectOrgProviders"
								itemValue="pmproviderid"
								itemText="name"
								v-model="model.providers"
								:items="allPmProviders"
								:itemsToSelect="model.providers"
								:disabled="formDisabled"
							></pui-multi-select>
						</v-layout>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="reloadStore"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { search, isSuperAdmin, getIconsType } from '@/api/common';
import constants from '@/utils/constants';

//import constants from '@/utils/constants';

export default {
	name: 'pmorganizationform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			iconSelected: null,
			modelName: 'pmorganization',
			model: 'pmorganization',
			allPmProviders: [],
			isSuperAdmin: false,
			items: [],
			rules: {
				select: [(v) => !!v || this.$t('common.required')]
			}
		};
	},
	computed: {
		dateFormatItemsToSelect() {
			return [{ pmdateformatid: this.model.pmdateformatid }];
		},
		subscriptionItemsToSelect() {
			return [{ pmsubscriptionid: this.model.pmsubscriptionid }];
		},
		languageItemsToSelect() {
			return [{ isocode: this.model.organizationlanguage }];
		},
		timezoneItemsToSelect() {
			return [{ pmtimezoneid: this.model.pmtimezoneid }];
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmorganizationid;
		}
	},
	watch: {
		iconSelected: {
			handler() {
				if (this.isSuperAdmin) {
					this.model.pmiconid = this.iconSelected ? this.iconSelected.pmiconsid : null;
				}
			},
			deep: true
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	beforeUpdate() {
		if (this.isSuperAdmin) {
			this.iconSelected = this.items.find((element) => element.pmiconsid == this.model.pmiconid);
		} else {
			this.iconSelected = this.items.find(
				(element) => element.pmiconsid == getIconsType(constants.DEFAULT_ORG_ICONS_TYPE, this.$store.getters.getIcons)[0].pmiconsid
			);
		}
	},
	updated() {
		console.log(this.model.providers);
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.items = getIconsType(constants.ORG_ICONS_TYPE_ACRONYM, this.$store.getters.getIcons).concat(
			getIconsType(constants.DEFAULT_ORG_ICONS_TYPE, this.$store.getters.getIcons)
		);
		this.items = this.items.sort((a, b) => {
			if (a.acronym < b.acronym) return -1;
			if (a.acronym > b.acronym) return 1;
			return 0;
		});
		console.log('Items:', this.items);
	},
	methods: {
		reloadStore() {
			if (this.$refs.form && this.$refs.form.validate && this.$refs.form.validate()) {
				this.userProperties.organizationdescription = this.model.description;
				this.userProperties.organizationname = this.model.name;
				this.userProperties.dateformat = this.model.dateformat;
				this.userProperties.acronym = this.model.acronym ?? null;
				this.userProperties.usesatelliteimages = this.model.usesatelliteimages ?? null;
				this.userProperties.shownotifications = this.model.shownotifications ?? null;
				this.userProperties.showaqi = this.model.showaqi ?? null;
				this.userProperties.showexternallayers = this.model.showexternallayers ?? null;
				this.userProperties.showgeojsonlayers = this.model.showgeojsonlayers ?? null;
				this.userProperties.city = this.model.city ?? null ?? null;
				this.userProperties.country = this.model.country ?? null;
				this.userProperties.email = this.model.email ?? null;
				this.userProperties.nelat = this.model.nelat ?? null;
				this.userProperties.nelon = this.model.nelon ?? null;
				this.userProperties.swlat = this.model.swlat ?? null;
				this.userProperties.swlon = this.model.swlon ?? null;
				this.retrieveDateFormatCharacteristics(this.model.pmdateformatid);
				console.log(this.model);
				console.log(this.userProperties);
			}
			this.save();
		},
		async afterGetData() {
			let filterProvider = null;
			if (!this.isSuperAdmin) {
				filterProvider = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				};
			}
			let body = {
				model: 'pmprovider',
				filter: filterProvider,
				rows: 100
			};

			let { data } = await search(body);
			console.log('After get data');
			console.log(data.data[0]);
			this.allPmProviders = data.data;
			/*
			body = {
				model: 'pnsubscriptionfiltertype',
				rows: null
			};

			({ data } = await search(body));
			this.allPmSubscriptionFilterTypes = data.data;
			*/
		},

		itemStyle(item) {
			var appProperties = this.$store.getters.getApplication;
			return 'content: url(' + appProperties.urlbase + item.route + '); margin-right: 1rem; transform: scale(0.7); width: 38px';
		},

		async retrieveDateFormatCharacteristics(dateFormatID) {
			const filterFormat = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmdateformatid', op: 'eq', data: dateFormatID }]
			};

			const body = {
				model: 'pmdateformat',
				filter: filterFormat
			};

			const { data } = await search(body);
			//console.log(data.data[0]);
			this.userProperties.acronymdateformat = data.data[0].acronym;
			this.userProperties.constants = data.data[0].constants;
			this.userProperties.example = data.data[0].example;
			this.userProperties.pmdateformatid = data.data[0].pmdateformatid;
		}
	}
};
</script>
<style scoped>
.label_container {
	position: relative;
}

.fixed_label {
	position: absolute !important;
	top: auto !important;
	left: 5px !important;
	color: rgba(0, 0, 0, 0.87);
}
</style>
